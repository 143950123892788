<template>
  <div class="pad-3 bg-cercles bg-cercles--mobile-0">
    <div class="bb-container">
      <main class="g-faq">
        <aside class="g-faq__side">
          <div class="side-nav">
            <h4 class="side-nav__title">{{ $t("faq.howCanWeHelpYou") }}</h4>
            <nav class="side-nav__items">
              <a
                style="cursor: pointer"
                :to="'#' + ayuda.anchor"
                class="side-nav__item"
                v-for="(ayuda, index) in ayudas"
                :key="index"
                :id="'a_' + ayuda.anchor"
                @click.prevent="scrollToSection(ayuda.anchor)"
              >
                <i v-if="ayuda.ico" :class="ayuda.ico"></i>
                <img v-else-if="ayuda.icoSvg" :src="ayuda.icoSvg" :alt="ayuda.header" />
                <img
                  v-else
                  src="../../assets/img/logos/logo-hellobb-isotip.svg"
                  alt="Isotip HelloBB"
                />
                <span>{{ ayuda.header }}</span>
              </a>
            </nav>
          </div>
        </aside>
        <section class="g-faq__right">
          <h1 class="header-2">{{ $t("faq.faq") }}</h1>

          <section class="faq-list">
            <FaqSection
              v-for="(section, index) in ayudas"

              :anchor="section.anchor"
              :i18nPrefix="section.i18nPrefix"
              :ico="section.ico"
              :items="section.items"
              :key="section.i18nPrefix"
            />
          </section>

          <div class="mar-4">
            <CtaFaqHelp></CtaFaqHelp>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
import CtaFaqHelp from "../cta/CtaFaqHelp.vue";
import Features from "../../Mixins/Features";
import FaqSection from "./components/FaqSection.vue";

export default {
  name: "ecommerceFaq",
  components: {
    CtaFaqHelp,
    FaqSection,
  },
  mixins: [
    // Required to use all the `feature*` getters.
    Features,
  ],
  metaInfo() {
    return {
      title: this.$t("faq.faq") + " | " + this.$t("generic.websiteTitle"),
      meta: [
        {
          name: "description",
          content: this.$t("faq.pageDescription"),
        },
        { name: "robots", content: "noindex" },
      ],
    };
  },
  data() {
    // NOTE: Getters aren't accessible from here, so don't use getters here
    // directly.
    //
    // If you need to use a getter, wrap it in a function and call it from
    // somewhere else (e.g. the `beforeMount` hook).
    return {
      ayudas: [
        {
          i18nPrefix: "faq.sections.about",
          ico: "",
          anchor: "nav-hellobb",
          items: [
            "doesItCostMoneyToMakeList",
            "isThereAnyLimitation",
            "unsubscribeFromEmails",
            "deleteAccount", 
            "haveItoBuyInHelloBB",
            "canIReceiveCash",
            "whoSendsAndHowIRecieveGifts",
            "hellobbButtonInstallation",
            "howCanIContact",
          ],
        },
        {
          i18nPrefix: "faq.sections.list",
          ico: "uil uil-list-ul",
          anchor: "nav-lista",
          items: [
            "how",
            "store",
            "preferences",
            "priority",
            {
              key: "whenAndHow",
              isVisible: () => this.featureCatalogAvailable,
            },
          ],
        },
        {
          i18nPrefix: "faq.sections.listManageAndShare",
          ico: "uil uil-share-alt",
          anchor: "nav-regalo",
          items: [
            "howShare",
            "canISee",
            "whatIfIModifyMyList",
            "whoSetAProductAsPurchased",
            "whatIfSomeoneSetsAsPurchasedByMistake",
            "knowingPurchasedThings",
            "howPeopleKnowThatSomethingIsPurchased",
            "knowingGiftedThings",
            "emailNotReceived",
            "canProductsBeReserved"
          ],
        },
        {
          i18nPrefix: "faq.sections.piggybank",
          isVisible: () => this.featureContributionsAvailable,
          icoSvg: "/ico-nav-hucha.svg",
          anchor: "nav-hucha",
          items: [
            "whatIsItFor",
            "howItWorks",
            "whatINeed",
            "whereMoneyStored",
            "howMuchItCosts",
            //"whoPaysTheTaxes",
            "whoPaysTheTaxesFree",
            "howLongItTakes",
            "canPeopleContributeOnTheSameGift",
            "canISetRestrictionOnContributions",
            "howToDisableContributions",
            "canReturnContribution",
            "whatAreFunds",
            "howToUseFunds",
          ],
        },
        {
          i18nPrefix: "faq.sections.rewardsProgram",
          isVisible: () => this.featureEcommerceAvailable,
          ico: "uil uil-money-withdraw",
          anchor: "nav-programa-recompensas",
          items: [
            "discontinued",
            "whatIsIt",
            "doesItRewardEverything",
            "howItWorks",
            "howAccumulates",
            "whatShouldIDo",
            "howCanISpend",
            "isThereAnyTimeLimitToRequest",
            "isThereAnyTimeLimitToSpend",
            "whatHappensIfIReturn",
            "deliveryFeesAccountForRewards",
          ],
        },
        {
          i18nPrefix: "faq.sections.deliveries",
          isVisible: () => this.featureEcommerceAvailable,
          ico: "uil uil-truck",
          anchor: "nav-envios",
          items: [
            "deliveryFees",
            "howLong",
            "where",
            "howCancel",
            "deliveryAddress",
            "instructions",
          ],
        },
        {
          i18nPrefix: "faq.sections.returns",
          isVisible: () => this.featureEcommerceAvailable,
          ico: "uil uil-redo",
          anchor: "nav-devoluciones",
          items: [
            "howItWorks",
            "whatCanIReturn",
            "doesGifterKnowReturns",
            "whenReceiveReturn",
          ],
        },
        {
          i18nPrefix: "faq.sections.configuration",
          ico: "uil uil-setting",
          anchor: "nav-configuracion",
          items: [
            "canIChangeBirthday",
            "canILeaveMessageToPeopleSharedWith",
            "canIAddPartnerToManageList",
          ],
        },
      ],
    };
  },
  methods: {
    scrollToSection(anchor) {
      console.log(anchor);
      const targetElement = document.getElementById(anchor);
      if (targetElement) {
        // Get the header height
        const headerElement = document.getElementById("header");
        const headerHeight = headerElement ? headerElement.offsetHeight : 0;

        // Scroll the target element into view and adjust for header height
        const elementRect = targetElement.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const adjustedPosition = absoluteElementTop - headerHeight - 80;

        window.scrollTo({ top: adjustedPosition, behavior: "smooth" });

        //targetElement.scrollIntoView({ behavior: "smooth" });
      }
    },
    isVisible(obj) {
      // Visible by default.
      let isVisible = true;

      if (typeof obj.isVisible !== "undefined") {
        switch (typeof obj.isVisible) {
          case "boolean":
            isVisible = obj.isVisible;
            break;
          case "function":
            isVisible = obj.isVisible();
            break;
          default:
            // Use default value.
            break;
        }
      }

      return isVisible;
    },
    expandItem(item) {
      if (typeof item === "string") {
        return {
          key: item,
        };
      }

      return item;
    },
    expandItems(items) {
      return items.map(this.expandItem);
    },
  },
  beforeMount() {
    this.ayudas = this.ayudas
      .map((section) => {
        const { i18nPrefix } = section;

        section.items = this.expandItems(section.items)
          .filter(this.isVisible)
          .map((item) => ({
            ...item,

            i18nPrefix,

            // Any item not visible was already filtered, so the only remaining
            // items are visible.
            //
            // The property is overriden for a primitive value to simplify any
            // further checks.
            isVisible: true,
          }));

        section.header = this.$t(`${section.i18nPrefix}.header`);

        return section;
      })
      .filter((section) => {
        return section?.items?.length > 0;
      })
      .filter(this.isVisible);
  },
  mounted() {
    const anchor = this.$route.hash || "";
    if (anchor && anchor.startsWith("#")) {
      const id = anchor.substring(1);

      this.scrollToSection(id);
    }
  },
};
</script>
